<template>
  <div>
    <div v-if="!showError" class="row align-items-start">
      <template v-if="itemsList.length > 0">
        <div v-for="item in itemsList" :key="item.id" class="col-6">
          <router-link :to="`/order/menu/item/${item.id}`" v-bind:class="darkModeBtn" class="btn shadow-5 mb-4 d-flex justify-content-center flex-column">
            <div class="row mb-2">
              <img :src="getIcon(categoryName)" alt height="50px" />
            </div>
            <div class="row">
              <h6 class="m-0 no-overflow">{{item.item_data.name}}</h6>
            </div>
          </router-link>
        </div>
      </template>
      <template v-else>
        <div v-for="list in 7" :key="list" class="col-6">
          <div class="mb-4">
            <content-loader :width="155" :height="95" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
              <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
              <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
              <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
              <rect x="0" y="0" rx="9" ry="9" width="155" height="95" />
            </content-loader>
          </div>
        </div>
      </template>
    </div>
    <div v-else v-on:click="retry" class="d-flex justify-content-center">
      <b-button type="is-danger" class="mt-3">
        <span>Retry</span>
        <b-icon pack="fas" icon="sync-alt"></b-icon>
      </b-button>
    </div>
  </div>
  <!-- <div>
    <div v-if="loading" class="row align-items-start">
      <div v-for="list in 3" :key="list" class="col-6">
        <div class="mb-4">
          <content-loader :width="155" :height="95" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
            <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
            <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
            <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
            <rect x="0" y="0" rx="9" ry="9" width="155" height="95" />
          </content-loader>
        </div>
      </div>
    </div>
    <div v-else class="row align-items-start">
      <div v-for="item in items" :key="item.id" class="col-6">
        <router-link :to="`/order/menu/item/${item.id}`" v-bind:class="darkModeBtn" class="btn shadow-5 mb-4 d-flex justify-content-center flex-column">
          <div class="row mb-2">
            <img :src="getIcon(categoryName)" alt height="50px" />
          </div>
          <div class="row">
            <h6 class="m-0 no-overflow">{{item.item_data.name}}</h6>
          </div>
        </router-link>
      </div>
    </div>
    <div v-if="showError" v-on:click="retry" class="d-flex justify-content-center">
      <b-button type="is-danger">
        <span>Retry</span>
        <b-icon pack="fas" icon="sync-alt" v-bind:class="[retryAwait ? 'fa-spin' : '']"></b-icon>
      </b-button>
    </div>
  </div>-->
</template>

<script>
import { ContentLoader } from "vue-content-loader"
import { darkModeStyle } from '../../../assets/js/darkMode';
import icons from '../../../assets/js/icons';

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      itemsList: [],
      categoryName: "",
      showError: false,
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light')
    }
  },
  methods: {
    async loadItems() {
      try {
        const itemList = await this.$store.dispatch('getItemsList', this.$route.params.id);
        const category = await this.$store.dispatch('getCategoryName', this.$route.params.id);

        this.itemsList = itemList;
        this.categoryName = category;

        this.showError = false;
      } catch (error) {
        this.$buefy.toast.open({ message: error.message, type: 'is-danger', queue: false, duration: 3000 });
        this.showError = true;
        throw error;
      }
    },
    async retry() {
      await this.loadItems();
    },
    getIcon(name) {
      return icons[name];
    }
  },
  async mounted() {

    const timeout = setTimeout(async () => {
      this.showError = true;
      this.$buefy.toast.open({ message: 'Server is not responding, please try again.', type: 'is-danger', queue: false, duration: 3000 });
    }, 5000);

    await this.loadItems();

    clearTimeout(timeout);
  }
}
</script>
