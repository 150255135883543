const icons = {
    'Food': require('../icons/Food.svg'),
    'Beer & Cider': require('../icons/Beer & Cider.svg'),
    'Cocktails': require('../icons/Cocktails.svg'),
    'Deserts': require('../icons/Deserts.svg'),
    'Pizza': require('../icons/Pizza.svg'),
    'Shots': require('../icons/Shots.svg'),
    'Soft Drinks': require('../icons/Soft Drinks.svg')
}

export default icons